<template>
  <div>
    <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
      <template v-slot:activator="{ on }">
        <v-chip pill small v-on="on">
          <v-avatar color="warning" left>
            <span class="white--text">{{ signal.name | initials }}</span>
          </v-avatar>
          {{ signal.name }}
        </v-chip>
      </template>
      <v-card width="300">
        <v-list dark>
          <v-list-item>
            <v-list-item-avatar color="warning">
              <span class="white--text">{{ signal.name | initials }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ signal.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ signal.variant }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="menu = false">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-network-strength-1-alert</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>{{ caseData.name }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-text</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>{{ signal.description }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="signal.external_url" :href="signal.external_url" target="_blank">
            <v-list-item-action>
              <v-icon color="primary">open_in_new</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>External Documentation</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "SignalPopover",

  data: () => ({
    menu: false,
  }),

  props: {
    signal: {
      type: Object,
      default: function () {
        return {}
      },
    },
    caseData: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
}
</script>
