<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Entity Type
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
          <v-alert outlined
            type="info"
            dismissible
            elevation="2"
            icon="mdi-format-superscript"
            prominent
          >
            <div>
              <h3>Entity Types: Enhancing Data Analysis</h3>
              <p>
                Entity Types facilitate the extraction of critical information, such as email addresses, account IDs, IP addresses, and more, from the raw data of Signal Instances. This process enables:
              </p>
              <ul>
                <li>Enabling automatic correlations to identify relationships between incidents.</li>
                <li>Utilizing engagement filters to streamline response processes.</li>
              </ul>
            </div>
          </v-alert>
        </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
          <v-col cols="12" sm="6" md="4" v-for="(item, index) in items" :key="index">
            <v-card class="ma-3 elevation-2">
              <!-- Item Name and Status -->
              <v-card-title class="justify-space-between mb-3">

                <div><v-icon left>mdi-format-superscript</v-icon>{{ item.name }}</div>
                <div>
                  <v-chip :color="item.enabled ? 'blue' : 'grey'" dark small class="mr-2">
                    <v-icon left small>{{ item.enabled ? 'mdi-lightbulb-on-outline' : 'mdi-lightbulb-off-outline' }}</v-icon>
                    {{ item.enabled ? 'Enabled' : 'Disabled' }}
                  </v-chip>
                  <!-- Scope Chips -->
                  <v-chip :color="getScopeColor(item.scope)" dark small class="mr-2">
                    <v-icon left small>{{ getScopeIcon(item.scope) }}</v-icon>
                    {{ getScopeText(item.scope) }}
                  </v-chip>
                  <!-- JPath and Regex -->
                  <v-chip :color="item.jpath ? 'primary' : 'grey'" dark small v-if="item.jpath" class="mr-2">
                    <v-icon left small>mdi-code-braces</v-icon>
                    JPath
                  </v-chip>
                  <v-chip :color="item.regular_expression ? 'success' : 'grey'" dark small v-if="item.regular_expression" class="mr-2">
                    <v-icon left small>mdi-regex</v-icon>
                    Regex
                  </v-chip>
                  <!-- Signal Definitions -->
                  <v-chip
                    v-if="item.signals && Array.isArray(item.signals)"
                    :color="item.signals.length > 0 ? 'purple' : 'grey'" dark small class="mr-2">
                    <v-icon left small>mdi-signal-variant</v-icon>
                    {{ item.signals.length }} Signal{{ item.signals.length > 1 ? 's' : '' }}
                  </v-chip>
                </div>
              </v-card-title>

              <!-- Signal Definitions if available -->
              <v-card-text>
                <div v-if="item.signals && Array.isArray(item.signals) && item.signals.length > 0">
                  <v-chip class="mb-2 mr-2" v-for="signal in item.signals" :key="signal.id" dark color="secondary" small>
                    <v-icon left small>mdi-signal</v-icon>
                    {{ signal.name }}
                  </v-chip>
                </div>
              </v-card-text>

              <!-- Actions -->
              <v-card-actions class="justify-end">
                <v-btn color="primary" text @click="createEditShow(item)">Edit</v-btn>
                <v-btn color="error" text @click="removeShow(item)">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      :items="items"
      :server-items-length="total"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="descending"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
    </v-data-table>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/entity_type/DeleteDialog.vue"
import NewEditSheet from "@/entity_type/NewEditSheet.vue"
import SignalPopover from "@/signal/SignalPopover.vue"

export default {
  name: "EntityTypeTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
    SignalPopover,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Description", value: "description", sortable: false },
        { text: "Scope", value: "scope", sortable: true },
        { text: "Enabled", value: "enabled", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("entity_type", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
    getScopeColor() {
      return (scope) => {
        if (scope === "global") {
          return "blue"
        } else if (scope === "multiple") {
          return "success"
        } else if (scope === "single") {
          return "secondary"
        } else {
          return "grey"
        }
      }
    },
    getScopeIcon() {
      return (scope) => {
        if (scope === "global") {
          return "mdi-earth"
        } else if (scope === "multiple") {
          return "mdi-account-multiple"
        } else if (scope === "single") {
          return "mdi-account"
        } else {
          return "mdi-help"
        }
      }
    },
    getScopeText() {
      return (scope) => {
        if (scope === "global") {
          return "Global"
        } else if (scope === "multiple") {
          return "Multiple"
        } else if (scope === "single") {
          return "Single"
        } else {
          return "Unknown"
        }
      }
    },
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("entity_type", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
